@use 'styles/base/mixins';
@use 'styles/base/variables';

.regularFilters,
.hotelFilters,
.showroomFilters {
  flex-direction: column;
  display: flex;
  gap: 1rem;
  width: 100%;

  @include mixins.screen-sm {
    flex-direction: row;
  }

  :global(.dropdown--disabled .dropdown__trigger) {
    background-color: variables.$gb_grey_060;
    cursor: default;
    color: variables.$gb_grey_400;
  }

  :global(.distanceFilter) {
    flex: 1;
  }
}

.regularFilters {
  form {
    border: unset;

    > div {
      z-index: 2;
    }

    ul li {
      padding-bottom: 0;
    }
  }

  ul li:last-of-type {
    padding-bottom: inherit;
  }

  li {
    font-weight: 400;
  }
}

.showroomFilters {
  ul li:last-of-type {
    padding-bottom: inherit;
  }
}

.filterBar {
  padding: 2rem 0;
  background-color: variables.$gb_white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  order: 1;
  margin: 0 auto;
  gap: 1rem;

  @include mixins.screen-md {
    padding: 1.5rem;
    order: initial;
    position: absolute;
    top: 1.25rem;
    left: 2.5rem;
    max-width: 28rem;

    & > div {
      flex: initial;
    }
  }

  @include mixins.screen-lg {
    left: 3rem;
  }

  .regularFilters,
  .showroomFilters {
    & > div {
      span {
        padding: 0;
      }

      @include mixins.screen-sm {
        flex: 1;
      }
    }
  }

  .hotelFilters {
    & > div {
      @include mixins.screen-sm {
        flex: 1;
      }
    }
  }

  .regularFilters {
    div:nth-child(2) {
      @include mixins.screen-md {
        flex-grow: 0;
        flex-basis: 7.75rem;
      }
    }
  }

  :global(& > div) {
    flex: 1;
  }

  .placeHolder {
    display: none;

    @include mixins.only-screen-sm {
      display: block;
    }
  }

  .modelFilterWrapper,
  .placeHolder {
    flex-basis: 100%;

    @include mixins.only-screen-sm {
      flex-basis: 50%;
    }
  }

  .modelFilterWrapper {
    @include mixins.screen-sm {
      margin-top: 0.625rem;
    }

    :global(form) {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    :global(.form-item) {
      margin-bottom: 0;
    }

    :global(.form-items) {
      padding: 1.25rem;
      margin-bottom: 0;

      :global(input[type='checkbox'] + span) {
        margin: 0 0 0.5rem;
      }
    }
  }

  .showroomFilters {
    :global(.dropdown) {
      width: auto;
      margin-top: 0.625rem;
      flex: 0.5;
      min-width: 0;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      :global(.dropdown__content) {
        max-height: 13.75rem;
        overflow: auto;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .hotelFilters {
    :global(.dropdown__content) {
      span {
        label {
          > span {
            margin-left: 1.75rem;
            &:before {
              left: -1.75rem;
            }
            &::after {
              left: -1.5rem;
            }
          }
        }
      }
    }
  }

  .showroomFilters,
  .regularFilters {
    :global(.dropdown__trigger),
    :global(.dropdown__trigger ul li button) {
      padding-left: 1rem;
      justify-content: flex-start;
      text-align: left;
    }
  }

  :global(.dropdown) {
    height: 2.8125rem;
    margin-top: 0.625rem;
    background-color: variables.$gb_white;

    @include mixins.screen-sm {
      margin-top: 0;
    }

    @include mixins.screen-md {
      margin-left: 0.625rem;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  :global(.dropdown--selected .dropdown__trigger) {
    color: variables.$gb-black;
  }

  :global(.dropdown__trigger) {
    align-items: center;
    background: variables.$gb_white;
    border: 1px solid variables.$gb_grey_200;
    color: variables.$gb_grey_650;
    cursor: pointer;
    display: flex;
    height: 100%;
    position: relative;
    padding: 0.6875rem 3.125rem 0.6875rem 1.375rem;
    text-align: left;
    text-decoration: none;

    &::after {
      top: 0.9375rem;
      right: 0.625rem;
      color: inherit;
    }
  }

  :global(.dropdown__content li) {
    padding: 0.625rem;

    & button {
      cursor: pointer;
      width: 100%;
    }
  }

  & #label {
    display: none;
  }
}

.filterWrapper {
  padding-right: 1rem;
  padding-left: 1rem;

  @include mixins.screen-sm {
    max-width: 76.875rem;
    width: 100%;
    left: 50%;
    z-index: 1;
    padding-right: 15.75%;
    padding-left: 15.75%;
  }

  @include mixins.screen-md {
    left: 0;
    z-index: 1;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    position: relative;
    max-width: 64rem;
    width: 64rem;
    margin: 0 auto;
  }

  @include mixins.screen-lg {
    max-width: 80rem;
    width: 80rem;
  }
}
