@use 'styles/base/mixins';
@use 'styles/base/variables';

.countrySelectorWrapper {
  &#{&} {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;

    @media (min-width: variables.$gb_breakpointSM) {
      margin-top: variables.$section-margin;
      margin-bottom: variables.$section-margin;
    }
  }
}

.countrySelector {
  display: grid;
  row-gap: 1rem;
  column-gap: 1.25rem;
  border-bottom: variables.$border-black;
  margin-bottom: variables.$spacing-xxl;
  padding-bottom: variables.$spacing-xxxl;

  &.withClosingLine {
    margin-bottom: 0;
  }

  .country {
    border: 1px solid variables.$gb_gdds_cl09;
    height: 3.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    color: variables.$gb_black;
    text-decoration: none;

    &::before {
      content: '';
      padding: 0;
    }

    &:hover {
      border: 1px solid variables.$gb_gdds_cl13;

      &.singleLanguage {
        .languageLinks {
          background-color: variables.$gb_gdds_cl13;
          color: variables.$gb_white;
        }
      }
    }

    &:active {
      border: 1px solid variables.$gb_gdds_cl15;

      &.singleLanguage {
        .languageLinks {
          background-color: variables.$gb_gdds_cl15;
          color: variables.$gb_white;
        }
      }
    }

    .flag {
      margin-right: 1rem;
      border: 1px solid variables.$gb_gdds_cl10;
      height: auto;
      width: 3rem;
      flex-shrink: 0;
    }

    .nameContainer {
      width: 100%;
      .name {
        width: fit-content;
        @include mixins.line-clamp(2);
      }
    }

    .languageLinks {
      background-color: variables.$gb_gdds_cl09;
      text-decoration: none;
      margin-left: 0.5rem;
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      color: variables.$gb_black;
      transition: none;
      align-items: center;

      &::before {
        content: '';
        padding: 0;
      }

      &:hover {
        color: variables.$gb_white;
        background-color: variables.$gb_gdds_cl13;
      }

      &:active {
        color: variables.$gb_white;
        background-color: variables.$gb_gdds_cl15;
      }
    }
  }
}

.disableBorder {
  border: none;
  padding-bottom: 0;
}

// tablet grid
@media (min-width: variables.$gb_breakpointSM) {
  .countrySelector {
    grid-template-columns: repeat(2, 1fr);
  }
}

// desktop grid
@media (min-width: variables.$gb_breakpointMD) {
  .countrySelector {
    grid-template-columns: repeat(3, 1fr);
  }
}
