@use 'styles/base/mixins';
@use 'styles/base/variables';

.textMediaColumnWrapper {
  margin: variables.$spacing-nordics-mobile;

  @include mixins.screen-md {
    margin: variables.$spacing-nordics-desktop;
  }

  .textMediaColumnHeadline {
    h2, h4 {
      margin-bottom: 0.625rem;

      @include mixins.screen-sm {
        margin-bottom: 1.5rem;
      }
    }
  }

  a,
  button {
    margin-top: 1.5rem;
  }
}
