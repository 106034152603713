@use 'styles/base/mixins';
@use 'styles/base/variables';

.previewImageWrapper {
  position: relative;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.videoPreviewImage {
  margin: 0;
  padding: 0 0 56.25%;
  height: 0;
  position: relative;
  background: center no-repeat;
  background-size: cover;
}

.videoPreviewFullWidthHeight {
  width: 100%;
  height: 100%;
  position: absolute;
}

.videoPreviewImageFullWidthHeight {
  padding: 0;
  width: 100%;
  height: 100%;
}

.video {
  margin: variables.$spacing-gdds-section-mobile-large;
  @include mixins.bullet-points-spacing();

  @include mixins.screen-sm {
    margin: variables.$spacing-gdds-section-large;
  }

  &.marginTopSmall {
    margin-top: 2rem;
  }

  &.marginBottomSmall {
    margin-bottom: 2rem;
  }

  &.greyBackground {
    background-color: variables.$gb_grey_050;
    margin: 0;
    padding: variables.$spacing-gdds-section-mobile-large;

    @include mixins.screen-sm {
      padding: variables.$spacing-gdds-section-large;
    }

    &.marginTopSmall {
      margin: 0;
      padding-top: 2rem;
    }

    &.marginBottomSmall {
      margin: 0;
      padding-bottom: 2rem;
    }
  }

  &.whiteBackground {
    background-color: variables.$gb_white;
  }

  div.headline {
    > h2 {
      margin-bottom: 0.625rem;

      @include mixins.screen-sm {
        margin-bottom: 1.5rem;
      }
    }
  }

  .text {
    p {
      max-width: none;
      font-size: 1rem;
    }
  }

  .player {
    margin-top: 1.25rem;

    @include mixins.screen-sm {
      margin-top: 1.5rem;
    }
  }

  .caption {
    color: variables.$gb_grey_600;
    margin-top: 0.5rem;
  }
}

.consent {
  position: relative;

  > div {
    padding: 1.5rem;
    justify-content: flex-start;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  > div > div {
    margin-top: 0;
  }

  button {
    position: absolute;
    bottom: 0;
    top: unset;
  }
}
