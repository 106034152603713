@use 'styles/base/mixins';
@use 'styles/base/variables';

.open {
  transition: max-height 0.6s ease-in-out;
  // used for animation since it has dynamic content height
  max-height: 40rem;
  margin-bottom: 0.625rem;
  overflow: hidden;

  &.nord {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-bottom: 0;
    overflow: unset;

    @include mixins.screen-sm {
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
    }

    @include mixins.screen-md {
      overflow: hidden;
    }
  }
}

.closed {
  transition: max-height 0.4s ease-out;
  max-height: 0;
  overflow: hidden;

  &.nord {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-bottom: 0;

    @include mixins.screen-sm {
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
    }
  }
}

.locatorDetail {
  font-size: 1rem;

  + p {
    margin-top: 1.25rem;
  }

  a {
    margin-right: 1rem;
  }

  ul {
    padding: 0;
    margin: 0;
    font-size: inherit;
  }

  li {
    list-style-type: none;
    padding: 0;
  }

  &.nord {
    display: flex;
    flex-direction: column;

    ul {
      margin-bottom: 0.5rem;
    }
  }
}

.sidebarAccWrapper {
  height: auto;
  box-shadow: variables.$box-shadow-inner-top-bottom;
  background-color: variables.$gb_grey_050;
  padding: 0.9375rem 0;

  &.nord {
    padding: 1rem 1rem 0.5rem 1rem;
    margin: 0.5rem 0 0.25rem 0;
    box-shadow: unset;

    @include mixins.screen-sm {
      padding: 1rem 1.5rem 0.5rem 1.5rem;
    }
  }

  ul {
    margin: 0;
    list-style: none;
  }

  .categoryList {
    flex-grow: 1;

    ul {
      list-style: disc;
    }
  }

  .modelTable {
    @include mixins.screen-sm {
      width: 75%;
    }
  }

  .locatorDetail,
  .categoryList {
    padding-left: 1.0625rem;

    &.nord {
      display: flex;
      flex-direction: column;
      padding-left: 0;
    }

    & + p {
      padding-left: 1.0625rem;
      margin-top: 0.625rem;

      a {
        padding: 0;

        &::before {
          vertical-align: baseline;
        }
      }
    }
  }
}

.modelTable {
  width: 100%;
  font-size: 0.875rem;

  table {
    width: 100%;
    font-size: 0.875rem;

    td,
    th {
      border-left: none;
    }

    tbody {
      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    td:last-child,
    th:last-child {
      text-align: center;
    }
  }
}

.showroomDetails {
  display: flex;
  flex-direction: column;
  align-content: space-between;

  @include mixins.screen-md {
    :global(.c-lightbox) & {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }

  :global(span) {
    font-size: 0.9375rem;
  }

  &.nord {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.showroomHeadline {
  margin: 1.4rem 0 0 0;
  display: block;
  font-size: 1.05rem;

  &.nord {
    margin-top: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

.categoryList {
  margin: 0 2rem 0 0;
  padding: 0 0 0.625rem;

  b {
    margin-top: 0.4rem;
    display: block;
  }

  span::after {
    content: ', ';
  }

  span:last-child::after {
    content: '';
  }

  @include mixins.screen-md {
    padding: 0;
  }

  &.nord {
    margin-top: 1rem;
  }
}

.locatorLightbox {
  flex-direction: column;
  text-align: left;
  height: 100%;

  .lightboxTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    :global(h4) {
      margin-bottom: 0;
      @include mixins.screen-sm {
        max-width: 200px;
      }
      @include mixins.screen-md {
        max-width: unset;
      }
    }

    :global(button) {
      align-self: flex-start;
      margin-bottom: 0;
    }
  }

  @include mixins.screen-md {
    min-width: 33.125rem;
  }

  .detailWrapper {
    width: auto;
    height: calc(100% - 120px);
    text-align: left;

    .locatorDetail {
      flex: 1;

      @include mixins.screen-sm {
        width: 29.375rem;
      }
    }
  }
}

.nord__lightbox__headline {
  .close_btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &#{&}#{&} h3.headline {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.75rem;
    max-width: calc(100% - 3.25rem);

    @include mixins.screen-sm {
      font-size: 1.5rem;
      line-height: 2rem;
      max-width: 29rem;
    }

    :global(.xy) & {
      font-size: 1.375rem;
      line-height: 1.75rem;

      @include mixins.screen-sm {
        font-size: 1.75rem;
        line-height: 2.25rem;
      }
    }
  }

  :global(.locator__detail) {
    margin-top: 1rem;
  }
}

.modalWrapper {
  > div:first-child {
    align-items: center;
    padding-top: 0;

    > div:first-child {
      height: unset;
    }
  }

  div[type='custom'] {
    align-items: center;
    max-height: 80vh;
    overflow: auto;
    padding-top: 0;
  }

  @include mixins.screen-sm {
    div[type='custom'] {
      width: unset;
    }
  }

  .showroomDetails {
    display: flex;
    flex-direction: column;
    align-content: space-between;

    @include mixins.screen-sm {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2.5rem;
    }

    .categoryList {
      margin-top: 1rem;
      padding: 0;

      @include mixins.screen-sm {
        margin: 1rem 0 0 0;
      }

      b {
        margin: 0;
      }
    }

    span {
      font-size: inherit;
    }
  }
}
