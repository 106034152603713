@use 'styles/base/mixins';
@use 'styles/base/variables';

.border {
  margin-top: 2.5rem;

  &.withBottomMargin {
    margin-bottom: 2.5rem;
  }

  @include mixins.screen-sm {
    margin-top: 5rem;

    &.withBottomMargin {
      margin-bottom: 5rem;
    }
  }
}
