@use 'styles/base/mixins';
@use 'styles/base/variables';

.sidebarWrapper {
  transition: all 300ms cubic-bezier(0, 0, 0.2, 1) 0s;
  background-color: #ffffff;
  order: 4;
  padding-right: 1rem;
  padding-left: 1rem;

  @include mixins.screen-sm {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @include mixins.screen-md {
    padding-right: 0;
    padding-left: 0;
    order: initial;
    position: absolute;
    width: 24rem;
    top: 1.5rem;
    right: 0;
    bottom: 1.5rem;
    margin: auto;
    z-index: 1;

    &.sidebarClosed {
      width: 0;
    }
    > div:last-child {
      padding: 1.5rem;
    }
  }

  @include mixins.screen-lg {
    width: 36rem;
  }

  :global(.loadmore-button) {
    margin-bottom: 1.5rem;

    @include mixins.screen-sm {
      max-width: 16rem;
    }

    span:last-child {
      width: initial;
      height: initial;
    }
  }
}

.sidebarToggleButton {
  position: absolute;
  left: -3rem;
  width: 3rem;
  height: 3rem;
  display: none;
  justify-content: center;
  align-items: center;

  @include mixins.screen-md {
    display: flex;
    top: 0rem;
  }

  > button {
    width: 3rem;
    height: 3rem;

    > span {
      width: 1.5rem;
      height: 1.5rem;
      min-width: unset;

      > svg {
        width: 100%;
      }
    }
  }
}

.listItem {
  display: flex;
  margin: 0 0 0.75rem 0;
  padding-left: 0;

  .distanceInfo {
    display: flex;
    flex: 1;
    height: 1.5rem;
    gap: 0.25rem;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5rem;

    @include mixins.screen-sm {
      padding-right: 5rem;
    }

    @include mixins.screen-md {
      padding-right: 0;
    }

    @include mixins.screen-lg {
      padding-right: 1rem;
    }

    :global(span) {
      display: inline-flex;
      align-items: center;
    }

    :global(img) {
      margin-top: 2px;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .generalInfoWrapper {
    display: flex;
    margin-bottom: 0;
  }

  .generalInfo {
    flex: 3;

    :global(.xy) & {
      font-size: 1rem;
    }

    .info {
      > span {
        display: block;

        &.info__name {
          font-weight: 700;
        }

        &.info__zip {
          display: inline;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .itemInfo {
    flex: 1;
  }

  .additionalInfo {
    > button {
      span:last-child {
        all: unset;
      }
    }
  }
}

.listWrapper {
  max-height: 100%;
  font-size: 0.9375rem;
  margin-top: 1rem;

  @include mixins.screen-sm {
    overflow-y: auto;
    margin-top: 1.5rem;
  }

  @include mixins.screen-md {
    margin-top: 0;
  }

  :global(button.button--type-link) {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gb-brand-primary-light);
    min-height: 1rem;

    span[data-preview-id] {
      min-height: auto;
    }

    i {
      margin-right: 8px;

      &::before {
        font-size: 12px;
      }
    }
  }
}
