@use 'styles/base/mixins';
@use 'styles/base/variables';

.consentContainer {
  width: 100%;
  height: 100%;
  background-color: variables.$gb_grey_800;
  color: variables.$gb_white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: variables.$spacing-m variables.$spacing-s;

  &#{&} .text {
    $element: &;
    margin-bottom: 1.375rem;

    p {
      color: variables.$gb_white;
      margin-bottom: 0;
    }

    a {
      color: variables.$gb_white;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 400;
      overflow: hidden;
      &:hover {
        > span {
          border-bottom-color: variables.$gb_white !important;
        }
        &::after {
          content: '';
          position: absolute;
          height: 1px;
          background-color: white;
          bottom: 0px;
          left: 20px;
          right: 0px;
        }
      }

      &::before {
        padding-right: 0.5rem;
        font-size: 0.75rem;
      }

      & > span:first-child {
        padding-right: 0;
      }

      &:before {
        content: '\f10b';
        color: white;
      }

      span {
        color: variables.$gb_white !important;
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }
}
